export const PRIVATE_NETWORKS_PATH = '/connections/private-data-source-connections';
export const PRIVATE_NETWORKS_PATH_WITH_ID = PRIVATE_NETWORKS_PATH + '/:id';
export const GCLOUD_PDC_SIGNING_TOKEN_PLACEHOLDER = 'GCLOUD_PDC_SIGNING_TOKEN';
export const PDC_DOCS_URL =
  'https://grafana.com/docs/grafana-cloud/connect-externally-hosted/private-data-source-connect/';

export const PDC_SQL_CONN_DEFAULT_MAX_LIFETIME_SECONDS = 300;
export const PDC_CONN_MAX_LIFETIME_OVERRIDE_DATASOURCES = ['mysql', 'mssql', 'grafana-postgresql-datasource'];

export enum SortType {
  Alphabetical = 'alphabetical',
  ReverseAlphabetical = 'reverse-alphabetical',
  CreatedDate = 'created-date',
  ReverseCreatedDate = 'reverse-created-date',
}

export const SORT_OPTIONS = [
  {
    value: SortType.Alphabetical,
    label: 'Sort by A-Z',
  },
  {
    value: SortType.ReverseAlphabetical,
    label: 'Sort by Z-A',
  },
  {
    value: SortType.CreatedDate,
    label: 'Created date (newest first)',
  },
  {
    value: SortType.ReverseCreatedDate,
    label: 'Created date (oldest first)',
  },
];
