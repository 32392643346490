import { APIEvent, BrowserConfig, TransportItem, initializeFaro } from '@grafana/faro-web-sdk';
import { config } from '@grafana/runtime';

export enum FARO_ENV {
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}

export const getFaroEnv = (appUrl: string) => {
  switch (true) {
    case appUrl.endsWith('grafana-ops.net'):
      return FARO_ENV.STAGING;
    case appUrl.endsWith('grafana.net'):
      return FARO_ENV.PROD;
    case appUrl.endsWith('grafana-dev.net'):
    case appUrl.endsWith('localhost'):
    default:
      return FARO_ENV.DEV;
  }
};

export function onBeforeSend(paths: string[], item: TransportItem<APIEvent>): TransportItem<APIEvent> | null {
  const isOnPluginPage = paths.some((path) => (item.meta.page?.url ?? '').indexOf(path) > 0);
  if (!isOnPluginPage) {
    return null;
  }

  return item;
}

export function getAppName(pluginName: string, environment: string) {
  return `${pluginName}-${environment}`;
}

type FaroConfig = Partial<BrowserConfig>;

export function initFaro({
  version,
  paths,
  faroConfig,
  pluginName,
  getUrl,
}: {
  version: string;
  paths: string[];
  faroConfig: FaroConfig;
  pluginName: string;
  getUrl: (environment: FARO_ENV) => string;
}) {
  const environment = getFaroEnv(new URL(config.appUrl).hostname);
  initializeFaro({
    app: {
      name: getAppName(pluginName, environment),
      version,
      environment,
    },
    url: getUrl(environment),
    isolate: true,
    globalObjectKey: pluginName + '-faro',
    beforeSend: (item) => onBeforeSend(paths, item),
    user: {
      id: String(config.bootData.user.id),
      username: config.bootData.user.name,
    },
    ...faroConfig,
  });
}
